/*
** Colors
*/

$color-white: #FFF;
$color-primary: #00913D;
$color-secondary: #00ACF1;
$color-teriary: #FFCA00;
$color-red: #E50C0C;
$color-dark: #353535;
$color-blue-dark: #00385B;
$color-blue: #0064A3;
$color-blue-light: #A2B2CA;
$color-light: #D2D2D2;
$color-light-1: #F8F8F8;
$color-light-2: #FAFAFA;
$color-gray: #969696;


/*
** Border
*/

$border-radius-xxs: 4px;
$border-radius-xs: 6px;
$border-radius-sm: 8px;
$border-radius-md: 12px;
$border-radius-lg: 16px;
$border-radius-circle: 50%;


/*
** Fonts
*/

$font-family: 'Raleway', sans-serif;

$font-size-xxs: 12px;
$font-size-xs: 14px;
$font-size-sm: 16px;
$font-size-md: 20px;
$font-size-lg: 24px;
$font-size-xl: 32px;
$font-size-xxl: 48px;

$font-weight-medium: 500;
$font-weight-semiBold: 600;
$font-weight-bold: 700;


/*
** Grid
*/

$max-width: 1248px;
$gutter: 16px;
$cols: 12;
