@import '../../styles/utils/variables.scss';

.radio {
  position: relative;
  height: 48px;
  display: flex;

  &--selected &__label {
    color: $color-blue;

    &::before {
      border-color: $color-blue;
    }

    &::after {
      opacity: 1 !important;
      transform: scale(1);
    }
  }

  &__label {
    color: $color-blue-light;
    font-size: $font-size-md;
    font-weight: $font-weight-medium;
    padding-left: 36px;
    display: inline-block;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 22px;
      height: 22px;
      border-radius: 100%;
      border: 2px solid $color-blue-light;
    }

    &::after {
      content: '';
      width: 18px;
      height: 18px;
      background: $color-blue;
      position: absolute;
      top: 4px;
      left: 4px;
      border-radius: 100%;
      transition: all 0.2s ease;
      opacity: 0;
      transform: scale(0);
    }
  }

  &__target {
    -webkit-appearance: none;
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}