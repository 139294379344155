* {
  line-height: normal;
  letter-spacing: normal;
  box-sizing: border-box;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  border: 0;
  cursor: pointer;
  background: none;
  outline: 0;
}

input,
textarea,
select {
  outline: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  margin: 0;
}

body {
  font-family: $font-family;
  font-weight: $font-weight-medium;
  background-color: $color-light-1;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}


/*
** Container and Row
*/

.container {
  max-width: $max-width;
  width: 100%;
  margin: 0 auto;
}

.row {
  display: flex;
}

.icon {
  display: flex;

  svg {
    width: 100%;
  }
}



/*
** Grid
*/

$columns: $cols;
$margin: $gutter;
$max_width: $max-width;

@for $i from 1 through $columns {
  $width: calc($max-width / $columns) * $i - $margin;

  .col--#{$i} {
    width: $width;
    margin: 0 calc($margin / 2);
  }
}
